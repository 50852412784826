











import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";

@Component({})
export default class TournamentInfoEnterPrize extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
}
