











import {Component, Prop, Vue} from "vue-property-decorator";
import {IAccountEntity} from "@/core/accounts/account.entity";

@Component({})
export default class TournamentAccountAvatar extends Vue {
  @Prop({ required: true, default: () => {} }) private readonly account!: IAccountEntity
  @Prop({ type: Number, required: false, default: 50 }) private readonly size!: number

  get iconAvatarSize () {
    return this.size + 10
  }
}
