





















import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {ITournamentEntity, TournamentState} from "@/core/tournaments/tournament.entity";
import {Moment} from "moment";
import {Timing} from "@/core/helpers/Timing";
import {CurrentTournamentStoreModule} from "@/store";

@Component({
  methods: {
    formatDuration: Timing.formatDuration
  },
  enums: {
    TournamentState
  }
})
export default class TournamentInfoTime extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
  @Prop({type: Object, required: false}) private readonly globalDateTimeNow!: Moment

  private interval: NodeJS.Timeout | null = null
  private localDateTimeNow: Moment = Timing.getTimeNow()

  get dateTimeNow () {
    if (!this.globalDateTimeNow) {
      return this.localDateTimeNow
    }

    return this.globalDateTimeNow
  }

  get timeToStart () {
    const diff = Timing.getTimeDiff(this.dateTimeNow, Timing.UnixToMoment(this.tournament.start_time))
    if (diff > 0) {
      return diff
    } else {
      return 0
    }
  }

  get timeToEnd () {
    const diff = Timing.getTimeDiff(this.dateTimeNow, Timing.UnixToMoment(this.tournament.end_time))
    if (diff > 0) {
      return diff
    } else {
      return 0
    }
  }

  get isTournamentStarted () {
    return this.timeToStart <= 0
  }

  private created () {
    if (!this.globalDateTimeNow) {
      this.startTimer()
    }
  }

  private startTimer () {
    this.interval = setInterval(() => {
      this.localDateTimeNow = Timing.getTimeNow()
    }, 1000)
  }

  private stopTimer () {
    if (this.interval != null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  beforeDestroy () {
    this.stopTimer()
  }

  @Watch('timeToStart')
  private onTimeToStartChange (val: number, oldVal: number) {
    // refresh tournament in start time
    if (oldVal > 0 && val <= 0) {
      console.log('Refresh after START')
      CurrentTournamentStoreModule.refreshCurrentTournament()
    }
  }

  @Watch('timeToEnd')
  private onTimeToEndChange (val: number, oldVal: number) {
    // refresh tournament in start time
    if (oldVal > 0 && val <= 0) {
      console.log('Refresh after END')
      CurrentTournamentStoreModule.refreshCurrentTournament()
    }
  }
}
