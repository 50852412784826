





















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {RouteNames} from "@/router/types";

@Component({
  enums: {RouteNames}
})
export default class TournamentPrivacyInfo extends Vue {
  @Prop({ type: Object, required: true, default: {} }) private readonly tournament!: ITournamentEntity
}
