








import {Component, Prop, Vue} from "vue-property-decorator";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";

@Component({})
export default class TournamentBackground extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

}
