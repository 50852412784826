








import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class TournamentSponsorAvatar extends Vue {
  @Prop({ type: String, required: true }) private readonly src!: boolean
  @Prop({ type: String, required: true }) private readonly alt!: boolean
  @Prop({ type: Number, required: false, default: 50 }) private readonly size!: boolean
  @Prop({ type: Boolean, required: false, default: false }) private readonly tile!: boolean
}
