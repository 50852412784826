











import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {Timing} from "@/core/helpers/Timing";

@Component({})
export default class TournamentInfoDateTime extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  get startDate (): string {
    return Timing.formatMoment(Timing.UnixToMoment(this.tournament.start_time), 'DD MMM YY hh:mm')
  }

  get endDate (): string {
    return Timing.formatMoment(Timing.UnixToMoment(this.tournament.end_time), 'DD MMM YY hh:mm')
  }
}
