















import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ITournamentEntity, TournamentState} from "@/core/tournaments/tournament.entity";

@Component({
  enums: {
    TournamentState
  }
})
export default class TournamentInfoState extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
}
