import {IAccountEntity} from "@/core/accounts/account.entity";
import {ITournamentConstraintEntity} from "@/core/tournaments/tournament-constraint.entity";
import {ITournamentParticipantEntity} from "@/core/tournaments/tournament-participant.entity";
import {ITournamentTargetModeEntity} from "@/core/tournaments/tournament-target-mode.entity";
import {ITournamentTargetParamEntity} from "@/core/tournaments/tournament-target-param.entity";

export enum TournamentType {
    RANKED = 'rank',
    NORMAL = 'norm',
}

export enum TournamentState {
    PENDING = 'PENDING',
    UNSUCCESSFUL = 'UNSUCCESSFUL',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}

export interface ITournamentEntity {
    id: number
    name: string
    start_time: number
    end_time: number
    duration: number
    type: TournamentType
    is_open: boolean
    is_individual: boolean
    max_participants: number
    min_participants: number
    current_participants: number
    owner_system_account_id: string
    game_id: number
    is_completed: boolean
    state: TournamentState
    ownerSystemAccount?: IAccountEntity
    constraints?: ITournamentConstraintEntity[]
    targetMode?: ITournamentTargetModeEntity
    targetParam?: ITournamentTargetParamEntity

    // custom fields
    leaderboard?: ITournamentParticipantEntity[]
    my_participation?: ITournamentParticipantEntity | null
    top_score?: ITournamentParticipantEntity | null
}