









import Vue from "vue";
import {Component, Emit, Prop} from "vue-property-decorator";
import {IPagination} from "@/core/service/types";

@Component({})
export default class Pagination extends Vue {
  @Prop({ type: Object, required: true }) private readonly pagination!: IPagination

  get currentPage () {
    return this.pagination.current_page + 1
  }

  get totalPages () {
    return this.pagination.total_pages
  }

  private onChange (newActivePage: number) {
    if (this.currentPage !== newActivePage) {
      this.onCurrentPageChange(newActivePage)
    }
  }

  @Emit()
  private onCurrentPageChange (newActivePage: number) {
    return newActivePage
  }
}
