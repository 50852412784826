











import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";
import {Getter} from "vuex-class";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import {StoreNamespace} from "@/store/types";
import {find} from 'lodash'

@Component({})
export default class TournamentInfoMyParticipantGameAccount extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  get gameAccount (): IGameAccountEntity | undefined {
    return find(this.gameAccounts, (gameAccount: IGameAccountEntity) => gameAccount.id === this.tournament.my_participation?.game_account_id)
  }
}
