







import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import {ITournamentJoinPopupData} from "@/components/Tournaments/Popups/TournamentJoinPopup.vue";
import {Getter} from "vuex-class";
import {AccountGameAccountsModuleGetterTypes} from "@/store/modules/account/gameAccounts/types";
import {StoreNamespace} from "@/store/types";
import {IGameAccountEntity} from "@/core/accounts/game-accounts/game-account.entity";

@Component({})
export default class TournamentSendAddRequestButton extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
  @Prop({type: Boolean, required: false, default: false}) private readonly withRedirect!: boolean

  @Getter(AccountGameAccountsModuleGetterTypes.GAME_ACCOUNTS, { namespace: StoreNamespace.AccountGameAccounts })
  private readonly gameAccounts!: IGameAccountEntity[]

  private onSendRequestAction () {
    if (this.gameAccounts.length > 0) {
      PopupsStoreModule.showPopup({
        popupType: PopupType.TournamentSendAddRequest,
        data: {
          tournament: this.tournament,
          withRedirect: this.withRedirect
        } as ITournamentJoinPopupData
      })
    } else {
      PopupsStoreModule.showPopup({
        popupType: PopupType.ConnectGameAccount
      })
    }
  }
}
