



































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {ITournamentEntity} from "@/core/tournaments/tournament.entity";
import TournamentInfoDateTime from "@/components/Tournaments/TournamentInfo/TournamentInfoDateTime.vue";
import TournamentInfoParticipantsInfo from "@/components/Tournaments/TournamentInfo/TournamentInfoParticipantsInfo.vue";
import TournamentInfoPrizePool from "@/components/Tournaments/TournamentInfo/TournamentInfoPrizePool.vue";
import TournamentInfoEnterPrize from "@/components/Tournaments/TournamentInfo/TournamentInfoEnterPrize.vue";
import TournamentInfoState from "@/components/Tournaments/TournamentInfo/TournamentInfoState.vue";
import TournamentInfoTime from "@/components/Tournaments/TournamentInfo/TournamentInfoTime.vue";
import TournamentInfoMyParticipantRole from "@/components/Tournaments/TournamentInfo/TournamentInfoMyParticipantGameAccount.vue";
import TournamentInfoMyParticipantStatus
  from "@/components/Tournaments/TournamentInfo/TournamentInfoMyParticipantStatus.vue";

@Component({
  components: {
    TournamentInfoMyParticipantStatus,
    TournamentInfoMyParticipantRole,
    TournamentInfoTime,
    TournamentInfoState,
    TournamentInfoEnterPrize, TournamentInfoPrizePool, TournamentInfoParticipantsInfo, TournamentInfoDateTime}
})
export default class TournamentInfoContainer extends Vue {
  @Prop({type: Object, required: true, default: {}}) private readonly tournament!: ITournamentEntity
  @Prop({ type: Boolean, required: false, default: false }) private readonly isGrid!: boolean
}
